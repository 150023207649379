
<template>
    <section>
        <Header/>
        <section class="main">
          <Feedback />
        <section>

          <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;">
            <h1 style="text-align:left;margin-left:10%;font-size:4vw;padding:10px 0px 10px 0px;">Resources</h1>
          </section>

            <section>
            <div class="introText">
              <!-- Accordian main start - overview and industries -->
                <template>
                    <div role="tablist" style="padding:20px 0px 20px 0px;">

                        <!-- ALL -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- ALL -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-0 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>All</b>

                                <span class="when-opened">
                                <img class="img-fluid" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-0" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>Canadian Centre for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/">CCOHS Website</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4 class="resource">Canadian Cancer Society (CCS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5 class="resource"><a class="resourceLink" target="_blank" href="https://data.prevent.cancer.ca/occupational">Occupational Burden of Cancer Dashboard</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/carcinogen-profiles/">Carcinogen Profiles & Estimates</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/resources/tools/">eWORK Tools</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/resources/exposure-reduction/">Exposure Reduction Resources</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/resources/videos/">Videos on Special Topics</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/burden/">Burden of Occupational Cancer Study</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.odsp-ocrc.ca/projects/odss/">Occupational Disease Surveillance System</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2019/national-burden-report/">Report: Burden of Occupational Cancer in Canada</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2017/occupational-burden-ontario-report/">Report: Burden of Occupational Cancer in Ontario - Major Workplace Carcinogens and Prevention of Exposure</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2020/whmis-cbi-report/">Report: Workplace Hazardous Materials Bureau – Confidential Business Information Protection Provisions on Carcinogens And Mutagens: Implications for Canadian Workers</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2012/the-examination-of-accepted-workplace-fatality-claims-within-ontario-and-canada/">The Examination of Accepted Workplace Fatality Claims Within Ontario and Canada</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Health Clinics for Ontario Workers Inc. (OHCOW)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://preventoccdisease.ca/en/index.html">Prevent Occupational Disease</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>


                        <!-- Asbestos -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-213 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Asbestos</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-213" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4 class="resource">Canadian Cancer Society (CCS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5 class="resource"><a class="resourceLink" target="_blank" href="https://cancer.ca/en/cancer-information/reduce-your-risk/be-safe-at-work/asbestos">Asbestos</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Canadian Centre for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/oshanswers/chemicals/asbestos/control.html">Asbestos - Control Strategies for Workplaces</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/oshanswers/chemicals/asbestos/effects.html">Asbestos – Health Effects</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/asbestos/">Asbestos Profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>CSA Group</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.csagroup.org/wp-content/uploads/CSA-Group-Research-Absbestos-Management-in-Canada.pdf">Asbestos Management in Canada: Assessing the Need for a National Standard</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Employment and Social Development Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.canada.ca/en/employment-social-development/services/health-safety/reports/asbestos-exposure-management-programs.html">Technical Guide to Asbestos Exposure Management Programs</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/burden/current-burden/asbestos-2/">Asbestos</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.odsp-ocrc.ca/asbestos-bulletin/">Occupational Risks for Asbestos-related Disease</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/OCRCBurdenofOccupationalCancerReport.pdf">OCRC Report: Burden of Occupational Cancer in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ministry of Labour, Training and Skills Development (Ontario)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.labour.gov.on.ca/english/hs/pubs/asbestos/">A Guide to the Regulation Respecting Asbestos on Construction Projects and in Buildings and Repair Operations</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>



                        <!-- CLEANING AGENTS -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-13 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Cleaning agents</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>Centre for Research Expertise in Occupational Disease (CREOD)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://creod.on.ca/wp-content/uploads/2019/01/WSPS-cleaning-agents.pdf">Causative Agent: Cleaning Agents</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Safety and Health Administration</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.osha.gov/sites/default/files/publications/OSHA3512.pdf">Protecting Workers Who Use Cleaning Chemicals</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Workers Health & Safety Centre (WHSC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.whsc.on.ca/What-s-new/News-Archive/Cleaning-products-damaging-worker-respiratory-health-study-confirms">Cleaning products damaging worker respiratory health, study confirms</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>





                        <!-- CONSTRUCTION -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- CONSTRUCTION -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-1 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Construction</b>

                                <span class="when-opened">
                                <img class="img-fluid" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/special-topics/sun-safety/">Addressing Sun Safety at Work in Canada</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Construction_Package_July-16-2015.pdf">Package Summaries - Construction Sector</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Lung_Carcinogen_Package_July-16-2015.pdf">Package Summary - Lung Carcinogens</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREXCanada_Diesel engine exhaust_OEL_REPORT_2019.pdf">"Setting an Occupational Exposure Limit for Diesel Engine Exhaust in Canada: Challenges and Opportunities"</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Infrastructure Health and Safety Association (IHSA)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://www.ihsa.ca/Homepage.aspx">Infrastructure Health & Safety Association</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/dee-infographic-construction/">Controlling Diesel Particulate Matter in Construction</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/Diesel engine exhaust-exposure-ontario-construction/">Diesel Engine Exhaust Exposure in the Ontario Construction Industry</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2016/prevention-in-construction/">Evaluation of Prevention Strategies for Reducing the Burden of Cancer in the Ontario Construction Industry</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/burden/future-burden/">Future Burden of Cancer in Construction</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2020/future-burden-construction-report/">Future Burden of Cancer in Construction Workshop Report</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://sunsafetyatwork.ca/ ">Sun Safety at Work</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/mesothelioma-cases-ontario-continue-rise">Cancer Facts - Mesothelioma Cases in Ontario Continue to Rise</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/occupation-related-prostate-cancer-risk-canadian-men">Cancer Facts - Occupation Related to Prostate Cancer Risk in Canadian Men</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/outdoor-workers-not-adequately-protected-sun">Cancer Facts - Outdoor Workers Not Adequately Protected From the Sun</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/some-ontario-workers-increased-risk-lung-cancer">Cancer Facts - Some Ontario Workers at Increased Risk of Lung Cancer</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/workplace-crystalline-silica-exposure-causes-200-lung-cancers-annually">Cancer Facts - Workplace Crystalline Silica Exposure Causes 200 Lung Cancer Cases Annually</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>




                        <!-- DIESEL ENGINE EXHAUST -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- DIESEL ENGINE EXHAUST -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-8 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Diesel engine exhaust</b>

                                <span class="when-opened">
                                <img class="img-fluid" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" src="../assets/overview_plus.svg" alt="resource plus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/diesel_engine_exhaust/">Diesel Engine Exhaust Profile</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/diesel_oel_report/">"Setting an Occupational Exposure Limit for Diesel Engine Exhaust in Canada: Challenges and Opportunities"</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Government of Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.canada.ca/en/employment-social-development/services/health-safety/reports/control-diesel-emissions.html">Control Measures for Diesel Engine Exhaust Emissions in the Work Place</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/OCRCBurdenofOccupationalCancerReport.pdf">OCRC Report: Burden of Occupational Cancer in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Workers Health and Safety Centre (WHSC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.whsc.on.ca/files/resources/hazard-resource-lines/diesel-exhaust-whsc-resource-line.aspx">Diesel Exhaust: It Takes Your Breath Away</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>



                        <!-- FOOD AND BEVERAGE-->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-2 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Food and beverage services</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/special-topics/sun-safety/">Addressing Sun Safety at Work in Canada</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Centre for Research Expertise for the Prevention of Musculoskeletal Disorders (CRE-MSD)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://uwaterloo.ca/centre-of-research-expertise-for-the-prevention-of-musculoskeletal-disorders/">Centre for Research Expertise for the Prevention of Musculoskeletal Disorders </a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Centre for Research Expertise in Occupational Disease (CREOD)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://creod.on.ca/">Centre for Research Expertise in Occupational Disease</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2011/shiftwork-in-canadian-industries-a-probable-cancer-risk-factor/">Shiftwork in Canadian Industries: A Probable Cancer Risk Factor</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://sunsafetyatwork.ca/">Sun Safety at Work</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/shiftwork-breast-cancer-health">Cancer Facts - Shiftwork, Breast Cancer and Health: Interventions to Reduce the Risks</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Workplace Safety and Prevention Services  (WSPS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.wsps.ca/Home.aspx">Workplace Safety and Prevention Services </a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>




                        <!-- Grain and flour dust -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- FLOUR AND GRAIN -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-221 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Grain and flour dust</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource plus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-221" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>Canadian Centre for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/oshanswers/diseases/farmers_lung.html">Farmer's Lung</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Government of Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.canada.ca/content/dam/canada/employment-social-development/services/health-safety/reports/grain-dust-general-info-EN.pdf">Grain dust</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.canada.ca/en/employment-social-development/services/health-safety/reports/flour-dust-exposure-limit.html">Occupational exposure limit (OEL) for flour dust</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Health and Safety Executive</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.hse.gov.uk/agriculture/topics/grain-dust.htm">Grain dust</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Worksafe BC</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.worksafebc.com/en/health-safety/hazards-exposures/flour-dust">Flour dust</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>
                       





                        <!-- HEALTHCARE -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- HEALTHCARE -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-3 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Healthcare</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Health_Care_Package_Oct_2019.pdf">Package Summaries - Health Care Sector</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/special-topics/antineoplastic-agents/">Workplace Exposure to Antineoplastic Agents</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Centre for Research Expertise in Occupational Disease (CREOD)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://creod.on.ca/">Centre for Research Expertise in Occupational Disease</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2016/antineoplastic-drugs-and-healthcare-workers/ ">Exposure to Antineoplastic Drugs Among Ontario's Healthcare Settings</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/antineoplastic-drug-handling-report/">OCRC Report: Assessment of Safe Antineoplastic Drug Handling Practices in Community Pharmacies, Veterinary Settings and Long-Term Care Homes in Ontario</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2011/shiftwork-in-canadian-industries-a-probable-cancer-risk-factor/">Shiftwork in Canadian Industries: A Probable Cancer Risk Factor</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/shiftwork-breast-cancer-health">Cancer Facts - Shiftwork, Breast Cancer and Health: Interventions to Reduce the Risks</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Public Services Health & Safety Association (PSHSA)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.pshsa.ca/">Public Services Health & Safety Association</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>


                       <!-- METAL MANUFACTURING -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- METAL MANUFACTURING -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-4 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Metal manufacturing</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Lung_Carcinogen_Package_July-16-2015.pdf">Package Summary - Lung Carcinogens</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Welder_Package_July-16-2015.pdf">Package Summary - Welder</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/occupational-cancer-and-asbestosis-among-asbestos-exposed-workers-in-ontario/">Occupational Cancer and Asbestosis Among Asbestos-Exposed Workers in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/workplace-crystalline-silica-exposure-causes-200-lung-cancers-annually">Cancer Facts - Workplace Crystalline Silica Exposure Causes 200 Lung Cancer Cases Annually</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Workplace Safety and Prevention Services  (WSPS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.wsps.ca/Home.aspx">Workplace Safety and Prevention Services </a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>



                        <!-- MINING -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- MINING -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-5 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Mining</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Mining_Package_July-16-2015.pdf">Package Summaries - Mining Sector</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Lung_Carcinogen_Package_July-16-2015.pdf">Package Summary - Lung Carcinogens</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREXCanada_Diesel engine exhaust_OEL_REPORT_2019.pdf">"Setting an Occupational Exposure Limit for Diesel Engine Exhaust in Canada: Challenges and Opportunities"</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Centre for Research in Occupational Safety and Health</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://crosh.ca/">Centre for Research in Occupational Safety and Health</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2017/controlling-dpm-in-mining/">Controlling Diesel Particulate Matter in Underground Mines</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2016/mmf-ontario-mining/ ">Lung Cancer and Chronic Respiratory Disease in the Ontario Mining Industry</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2020/mcintyre-powder-study/">McIntyre Powder Study</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2020/mcintyre-powder-report/">OCRC Report: Investigation of McIntyre Powder Exposure and Neurological Outcomes in the Mining Master File Cohort</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2010/ontario-uranium-miner-cohort/">Ontario Uranium Miner Cohort: Linkage with National Mortality and Cancer Incidence Files</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/some-ontario-workers-increased-risk-lung-cancer">Cancer Facts - Some Ontario Workers at Increased Risk of Lung Cancer</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/workplace-crystalline-silica-exposure-causes-200-lung-cancers-annually">Cancer Facts - Workplace Crystalline Silica Exposure Causes 200 Lung Cancer Cases Annually</a></h5>
                                  </div>
                                
                                  <div class="resourceGroup">
                                    <h4>Workplace Safety North (WSN)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.workplacesafetynorth.ca/">Workplace Safety North</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>


                        <!-- NICKEL -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-24 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Nickel</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-24" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/nickel/">Nickel profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>The National Institute for Occupational Health and Safety (NIOSH):</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cdc.gov/niosh/topics/nickel/default.html">Nickel</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>



                        <!-- Polycyclic aromatic hydrocarbons (PAHs) -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-14 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Polycyclic aromatic hydrocarbons</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-14" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>Agency for Toxic Substances and Disease Registry (ATSDR)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.atsdr.cdc.gov/csem/polycyclic-aromatic-hydrocarbons/who_is_at_risk.html">Who Is at Risk of Exposure to PAHs?</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/polycyclic_aromatic_hydrocarbons/">PAHs profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://www.occupationalcancer.ca/wp-content/uploads/2020/01/PAH_Burden_FS_2019.pdf">Polycyclic Aromatic Hydrocarbons</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>



                        <!-- PROTECTIVE SERVICES -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- PROTECTIVE SERVICES -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-6 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Protective services</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/special-topics/sun-safety/">Addressing Sun Safety at Work in Canada</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREX_Firefighters_Package_Jan-26-2016.pdf">Package Summary - Firefighter</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREXCanada_Diesel engine exhaust_OEL_REPORT_2019.pdf">"Setting an Occupational Exposure Limit for Diesel Engine Exhaust in Canada: Challenges and Opportunities"</a></h5>
                                  </div>


                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Center (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/dee-controls-infographic-on-road/">Controlling Diesel Particulate Matter from On-Road Diesel Engines</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2016/diesel-exhaust-fire-halls/ ">Diesel exhaust exposure in Ontario fire halls</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2018/investigating-the-occupational-etiology-of-prostate-cancer-in-canadian-workers/">Investigating the etiology of prostate cancer in Canadian workers</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://www.occupationalcancer.ca/wp-content/uploads/2018/06/DEE-Fire-Halls-Report.pdf">OCRC Report: Assessment of diesel exhaust exposure in municipal fire halls in Ontario</a></h5>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.occupationalcancer.ca/2011/shiftwork-in-canadian-industries-a-probable-cancer-risk-factor/">Shiftwork in Canadian Industries: A Probable Cancer Risk Factor</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://sunsafetyatwork.ca/ ">Sun Safety at Work</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/occupation-related-prostate-cancer-risk-canadian-men">Cancer Facts - Occupation Related to Prostate Cancer Risk in Canadian Men</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/some-ontario-workers-increased-risk-lung-cancer">Cancer Facts - Some Ontario Workers at Increased Risk of Lung Cancer</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Public Services Health & Safety Association (PSHSA)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.pshsa.ca/">Public Services Health & Safety Association</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>



                        <!-- SILICA -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- SILICA -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-9 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Silica</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource plus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/silica_crystalline/">Silica (Crystalline) Profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/OCRCBurdenofOccupationalCancerReport.pdf">OCRC Report: Burden of Occupational Cancer in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario’s Ministry of Labour, Training, and Skills Development</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.labour.gov.on.ca/english/hs/pubs/silica/">Silica on Construction Projects</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/workplace-crystalline-silica-exposure-causes-200-lung-cancers-annually">Cancer Facts - Workplace Crystalline Silica Exposure Causes 200 Lung Cancer Cases Annually</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Worksafe BC</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.worksafebc.com/en/health-safety/hazards-exposures/silica">Silica</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>




                        <!-- TRANSPORTATION -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- TRANSPORTATION -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-7 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Transportation</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/special-topics/sun-safety/">Addressing Sun Safety at Work in Canada</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/CAREXCanada_Diesel engine exhaust_OEL_REPORT_2019.pdf">"Setting an Occupational Exposure Limit for Diesel Engine Exhaust in Canada: Challenges and Opportunities"</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Infrastructure Health and Safety Association (IHSA)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://www.ihsa.ca/Homepage.aspx">Infrastructure Health & Safety Association</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/occupation-related-prostate-cancer-risk-canadian-men">Cancer Facts - Occupation Related to Prostate Cancer Risk in Canadian Men</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/some-ontario-workers-increased-risk-lung-cancer">Cancer Facts - Some Ontario Workers at Increased Risk of Lung Cancer</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>





                        <!-- UV RADIATION -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- UV RADIATION -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-10 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>UV radiation</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource plus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/uv_radiation_solar/">Solar UV Radiation Profile</a></h5>
                                  </div>
                                  <div style="background-color:#EAEAEB;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/en/cancer-facts/some-ontario-workers-increased-risk-lung-cancer">Addressing Sun Safety at Work</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/OCRCBurdenofOccupationalCancerReport.pdf">OCRC Report: Burden of Occupational Cancer in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>World Health Organization (WHO)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.who.int/uv/publications/en/occupational_risk.pdf?ua=1#:~:text=Health%20Organization%2C%202003-,ULTRAVIOLET%20RADIATION%20AS%20A%20HAZARD%20IN%20THE%20WORKPLACE,from%20exposure%20to%20UV%20radiation">UV Radiation as a Hazard in the Workplace</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>



                        <!-- WELDING FUMES-->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-12 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Welding fumes</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/welding-fumes/">Welding fumes profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Canadian Centre for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/oshanswers/safety_haz/welding/fumes.html">Welding- Fumes and Gases</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Employment and Social Development Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.canada.ca/en/employment-social-development/services/health-safety/reports/guide-welding.html">Welding and allied processes: A guide to health hazards and hazard control measures</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Infrastructure Health and Safety Association (IHSA)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ihsa.ca/rtf/health_safety_manual/pdfs/tools_and_techniques/Welding_and_Cutting.pdf">Welding and Cutting</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="http://www.occupationalcancer.ca/wp-content/uploads/2020/01/Welding_Fumes_Burden_FS_2019.pdf">Welding and welding fumes</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>






                        <!-- WOOD DUST -->
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                            <!-- WOOD DUST -->
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-11 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>Wood dust</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource plus" />
                                </span>
                            </b-button>
                            </b-card-header>

                            <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>CAREX Canada</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.carexcanada.ca/profile/wood_dust/">Wood Dust Profile</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Canadian Centre for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/oshanswers/chemicals/wood_dust.html">Wood Dust – Health Effects</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Occupational Cancer Research Centre (OCRC)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/OCRCBurdenofOccupationalCancerReport.pdf">OCRC Report: Burden of Occupational Cancer in Ontario</a></h5>
                                  </div>

                                  <div class="resourceGroup">
                                    <h4>Ontario Health (Cancer Care Ontario Division)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.cancercareontario.ca/sites/ccocancercare/files/assets/CCORiskFactorsDustFibres.pdf">Cancer Risk Factors in Ontario: Dust and Fibres</a></h5>
                                  </div>

                                </b-card-body>
                            </b-collapse>

                        </b-card>

                    </div>
                </template>
            </div>
          </section>

        </section>
        </section>
        <Footer/>


<!-- 

          <section>
            <div class="introText">
               
                <template>
                    <div role="tablist" style="padding:20px 0px 20px 0px;">

                       
                        <b-card no-body class="mb-1" style="background-color:#0A2940;">

                           
                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle.accordion-0 variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>All</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                           
                            <b-collapse id="accordion-0" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div class="resourceGroup">
                                    <h4>Canadian Center for Occupational Health and Safety (CCOHS)</h4>
                                  </div>
                                  <div style="background-color:white;width:96%;padding:10px;">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/">CCOHS Website</a></h5>
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>

                        <b-card no-body class="mb-1" style="background-color:#0A2940;" v-for="(sector, index) in sectors" :key="index">
                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:#0A2940;border:none;">
                            <b-button class="accTitle" block v-b-toggle="'accordion-' + sector.id" variant="info" style="background-color:#0A2940;border:none;font-size:1.2em !important;height:50px;">
                                <b>{{sector.sector_name}}</b>

                                <span class="when-opened">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_minus.svg" alt="resource minus" />
                                </span>
                                <span class="when-closed">
                                <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/overview_plus.svg" alt="resource minus" />
                                </span>
                            </b-button>
                            </b-card-header>

                           
                            <b-collapse :id="'accordion-' + sector.id" accordion="my-accordion" role="tabpanel" style="background-color:white;margin:0;font-size:15px;">
                                <b-card-body>
                                  <div v-for="(org, index1) in filteredResources[sector.id]" :key="index1">
                                  <div class="resourceGroup">
                                    <h4>{{organizations[index1].org_name}}</h4>
                                  </div>
                                   <div style="background-color:white;width:96%;padding:10px;" v-for="(o, i) in org" :key="i">
                                    <h5><a class="resourceLink" target="_blank" href="https://www.ccohs.ca/">{{resources[i].title}}</a></h5>
                                  </div>  
                                  </div>
                                </b-card-body>
                            </b-collapse>

                        </b-card>

                    </div>
                </template>
            </div>
          </section>

          
        </section>


        </section>
        <Footer/> -->
    </section>
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash });

Vue.use(VueHead, VueAxios, axios)

export default {
  components: {
    Header,
    Footer,
    Feedback
  },
  data() {
    return {
      intro_text : '',
      resources: [],
      sectors: [],
      organizations: {}
    }
  },
  methods: {
    getResources() {
      axios.get("https://www.occdiseasestats.ca/php/data.php?action=getResources").then(response => {
        this.resources = response.data;
      });
    },
    getSectors() {
      axios.get("https://www.occdiseasestats.ca/php/data.php?action=getSectors").then(response => {
        this.sectors = this.lodash.orderBy(response.data, 'sector_name', 'asc');
      });
    },
    getOrganizations() {
      axios.get("https://www.occdiseasestats.ca/php/data.php?action=getOrganizations").then(response => {
        this.organizations = response.data; //this.lodash.sortBy(response.data, 'org_name', 'asc');
      });
    }
  },
  computed: {
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    filteredResources: function() {
        let res = [];
        let resources = this.resources;
        let organizations = this.organizations;
        // let style1 = "background-color:white;width:96%;padding:10px;";
        // let style2 = "background-color:#EAEAEB;width:96%;padding:10px;"
        //let colorSwitch = 0;
        //let ld = this.lodash;
        // the style isn't alternating correctly
        // actual organizations and their resources need to be sorted alphabetically
        this.lodash.forEach(resources, function(r) {
            if (res[r.sector_id] == undefined) {
                res[r.sector_id] = [];
                res[r.sector_id]["organizations"] = [];
                res[r.sector_id]["sectorID"] = r.sector_id;
            }
            //let org = organizations[r.org_id].org_name;
            if (res[r.sector_id]["organizations"][r.org_id] == undefined) {
              res[r.sector_id]["organizations"][r.org_id] = [];
              res[r.sector_id]["organizations"][r.org_id]["resources"] = {};
               let org = organizations[r.org_id].org_name;
              res[r.sector_id]["organizations"][r.org_id]["name"] = org;
              // const sortArray = (res[r.sector_id]["organizations"]).sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}));
              // console.log(sortArray);
              //colorSwitch = 0;
            }
          
            
            // if (res[r.sector_id]["organizations"] != undefined) {
            //   const sortArray = (res[r.sector_id]["organizations"][r.org_id]).sort((a,b) => a.name.localeCompare(b.name, 0, {caseFirst: 'lower'}));
            //   console.log(sortArray);
            // }
            if (res[r.sector_id]["organizations"][r.org_id]["resources"][r.id] == undefined){
              res[r.sector_id]["organizations"][r.org_id]["resources"][r.id] = r;
              // if ((Math.abs(colorSwitch % 2) == 1) == true) {
              //   res[r.sector_id]["organizations"][r.org_id]["resources"][r.id]["style"] = style2;
              //   colorSwitch++;
              // }else{
              //   res[r.sector_id]["organizations"][r.org_id]["resources"][r.id]["style"] = style1;
              //   colorSwitch++;
              // }
            }
        });
        
        return res;
    },
    sortedResources: function () {
      this.lodash.forEach(this.filteredResources, function(r) {
        console.log(r);
      });
      return "";
    },
    filteredSectors: function() {
      let p = [];
      p[0] = {"sector_name":"All"};
      this.lodash.forEach(this.sectors, function(s) {
        p[s.id] = s;
      });
      const sortedP = p.sort((a,b) => a.sector_name.localeCompare(b.sector_name, 0, {caseFirst: 'lower'}));
      console.log(sortedP);
      return p;
    },
    filteredOrgs: function () {
      let orgs = this.organizations; 
      return orgs.sort((a,b) => a.org_name.localeCompare(b.org_name, 0, {caseFirst: 'lower'}))
    }
  },
  head: {
    title:{
      inner: 'Resources | Ontario Occupational Disease Statistics'
    }
  },
  mounted: function() {
    this.getSectors();
    this.getOrganizations();
    this.getResources();
  },
}
</script>

<style>

html > body > div.introText > a.resourceLink {
  color:#444444 !important;
  text-decoration:none;
  text-decoration-color:#444444 !important;
}

a.resourceLink:hover {
  color:#E7401C;
}

.resourceGroup {
  background-color:#C0C7CD;
  color:#0A2940;
  width:96%;
  padding:6px;
}

.resourceGroup > h4{
  font-weight:bold;
  margin-top:5px;
}

.when-opened {
  float:right;
  width:40px;
}

.when-closed {
  float:right;
  width:40px;
}

</style>
